@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  height: 100vh;
  max-height: fit-content;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', 'Inter', 'Segoe UI', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4b4b4b;
  background: linear-gradient( #626262 0%, #484848 50%, #323232 100%);
  transition: all 0.3s ease;
}

code {
  font-family: 'Roboto Mono', 'Courier New', monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Roboto+Mono:wght@300;400;500;600;700&display=swap");

.theme-transition {
  transition: all 0.3s ease;
}


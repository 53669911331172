
.blur-background {
  position: fixed;
  top: 0;
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  backdrop-filter: blur(6px);
  z-index: 0;
  width: 100vw;
  height: 100vh;
}

.myDiv {
  border: 1px solid #3c3c3c;
  /* width: 30vw; */
  padding: 20px;
  width: 900px;
  height: 400px;
  left: 25%;
  top:25%;
  /* bottom: 0; */
  position: relative;
  z-index: 1;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #ffffff;
  border-radius:"15px"

}

.custom-datepicker {
  width: 100%;
  background: transparent;
  color: #fff;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: none;
  border: none;
}

.custom-datepicker {
  border-radius: 4px;
  padding: 0px;
}

.custom-datepicker-container:hover .custom-datepicker,
.custom-datepicker-container:focus-within .custom-datepicker {
  border: 2px solid #007bff;
}
.custom-datepicker:focus {
  outline: none;
}


::-webkit-scrollbar {
    width: 5px; 
    border: none;
  }
  
  ::-webkit-scrollbar-track {
    background: #4a4a4a; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #929292; 
    border-radius: 6px;
    opacity: 1;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #2a2a2a; 
  }
  
  .custom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-icon img {
    display: block;
    width: 30px;
    height: 30px;
  }

  .toggle-switch {
    width: 45px;
    height: 20px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.3s;
}

.toggle-switch.on {
    background-color: #4caf50;
}

.toggle-slider {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
}

.toggle-switch.on .toggle-slider {
    transform: translateX(30px);
}

.popup{
  height: 5;
  /* max-height: 100px; */
}

  

.line {
    border-top: 25px solid ;
    /* background: 'linear-gradient( #626262 0%, #626262 50%, #626262 100%)', */
    background: '#000000';
  }
  

  @media (max-width: 768px) {
    .MuiCard-root {
      width: 48%;
      margin-right: 1%;
      margin-top: 2%;
    }
  }
  
  @media (max-width: 480px) {
    .MuiCard-root {
      width: 100%;
      margin-right: 0;
      margin-top: 4%;
    }
  }